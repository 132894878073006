import { Controller } from '@hotwired/stimulus';
const html2canvas = require('html2canvas');
import { MDCDialog } from '@material/dialog';

export default class extends Controller {
  static targets = ['dialog', 'logicModel'];

  dialogTargetConnected() {
    this.dialog = new MDCDialog(this.dialogTarget);
  }

  openDialog() {
    this.dialog.open();
  }

  modelScreenshotPng() {
    if (this.logicModelTarget) {
      html2canvas(this.logicModelTarget).then((canvas) => {
        const base64img = canvas.toDataURL('image/png');
        let a = document.createElement('a');
        a.download = 'irlm-model.png';
        a.href = base64img;
        a.click();
      });
    }
  }

  modelScreenshotJpeg() {
    html2canvas(this.logicModelTarget).then((canvas) => {
      const base64img = canvas.toDataURL('image/jpeg');
      let a = document.createElement('a');
      a.download = 'irlm-model.jpg';
      a.href = base64img;
      a.click();
    });
  }

  disableLink(event) {
    const link = event.target.closest('.mdc-touch-target-wrapper');
    link.classList.add('display-none');
    link.nextElementSibling.classList.remove('display-none');
  }
}
